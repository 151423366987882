/** @define banner-hp; weak */
.banner-hp {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 360px;
}
@media only screen and (min-width: 800px) {
  .banner-hp {
    height: 838px;
  }
}
@media only screen and (max-width: 399px) {
  .banner-hp {
    background: url("/images/banner-hp/header_hp_400.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 400px) and (max-width: 799px) {
  .banner-hp {
    background: url("/images/banner-hp/header_hp_800.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1399px) {
  .banner-hp {
    background: url("/images/banner-hp/header_hp_1400.webp") no-repeat top center;
  }
}
@media only screen and (min-width: 1400px) {
  .banner-hp {
    background: url("/images/banner-hp/header_hp_2560.webp") no-repeat top center;
  }
}
.no-webp {
  /* postcss-bem-linter: ignore */
}
@media only screen and (max-width: 399px) {
  .no-webp .banner-hp {
    background: url("/images/banner-hp/header_hp_400.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 400px) and (max-width: 799px) {
  .no-webp .banner-hp {
    background: url("/images/banner-hp/header_hp_800.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1399px) {
  .no-webp .banner-hp {
    background: url("/images/banner-hp/header_hp_1400.jpg") no-repeat top center;
  }
}
@media only screen and (min-width: 1400px) {
  .no-webp .banner-hp {
    background: url("/images/banner-hp/header_hp_2560.jpg") no-repeat top center;
  }
}
.banner-hp__text {
  position: relative;
  justify-content: center;
  height: calc(100% - 146px);
  padding: 0 10px 20px 10px;
  text-align: center;
  /*@media only screen and (min-width: 700px) {
    height: calc(100% - 96px);
  }*/
  /*@media only screen and (min-width: 900px) {
    height: calc(100% - 170px);
  }*/
}
@media only screen and (max-width: 799px) {
}
@media only screen and (min-width: 800px) {
  .banner-hp__text {
    justify-content: flex-start;
    height: calc(100% - 126px);
    padding: 0 0 40px 65px;
    text-align: left;
  }
  .banner-hp__text .banner-hp__textcontent {
    max-width: 600px;
  }
}
@media only screen and (min-width: 1040px) {
  .banner-hp__text {
    height: calc(100% - 180px);
  }
}
@media only screen and (min-width: 1450px) {
  .banner-hp__text {
    height: calc(100% - 140px);
  }
}
.banner-hp__text h1 {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 22px;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
  text-shadow: 2px 2px 4px #000000;
}
@media only screen and (min-width: 800px) {
  .banner-hp__text h1 {
    margin-bottom: 20px;
    font-size: 50px;
  }
}
.banner-hp__text p {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.7);
}
@media only screen and (min-width: 500px) {
  .banner-hp__text p {
    padding: 10px;
  }
}
@media only screen and (min-width: 800px) {
  .banner-hp__text p {
    margin-bottom: 20px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 1299px) {
  .banner-hp__text p {
    text-shadow: 2px 2px 4px #000000;
  }
}
@media only screen and (min-width: 800px) {
  .banner-hp__text a {
    text-transform: uppercase;
  }
}
.banner-hp__text span {
  /*@media only screen and (max-width: 799px) {
      display: none;
    }*/
}
@media only screen and (min-width: 800px) {
  .banner-hp__text span {
    display: block;
  }
}
.banner-hp__text .continue_icon {
  position: absolute;
  bottom: 8px;
  left: 50%;
  /*@media only screen and (min-width: 800px) {
      bottom: 40px;
    }*/
}
@media only screen and (min-width: 800px) {
  .banner-hp__text .continue_icon {
    bottom: -2px;
  }
}
/*# sourceMappingURL=css/banner-hp.css.map */